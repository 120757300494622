import VALUE_TYPES from "common/services/persistence/ValueTypes"

export default {
    hash: {
        modalToOpenName: {
            key: "modalToOpenName",
            type: VALUE_TYPES.STRING,
        },
        reportId: {
            key: "reportId",
            type: VALUE_TYPES.STRING,
        },
        myBrandroomSelection: { key: "mbrs", type: VALUE_TYPES.STRING },
        videoChat: { key: "vc" },
        loginHint: { key: "loginHint" },
        octoprintServerId: { key: "octoprintServerId" },
    },
    localStorage: {
        shareFormContent: { key: "shareFormContent", type: VALUE_TYPES.OBJECT },
        helpDeskFormContent: {
            key: "helpDeskFormContent",
            type: VALUE_TYPES.OBJECT,
        },
        hiddenDialogs: { key: "hiddenDialogs", type: VALUE_TYPES.ARRAY, defaultValue: [] },
        modalToOpenProps: {
            key: "modalToOpenProps",
            type: VALUE_TYPES.OBJECT,
            defaultValue: {},
        },
        cloudPrintPreferences: { key: "cloudPrintPreferences", type: VALUE_TYPES.OBJECT },
        recomposerSelectSections: {
            key: (documentId) => `recomposerSelectSections_${documentId}`,
            type: VALUE_TYPES.ARRAY,
            defaultValue: [],
        },
    },
    sessionStorage: {
        shareFormContent: {
            key: "shareFormContent",
            type: VALUE_TYPES.OBJECT,
            defaultValue: {},
        },
        tracking: { key: "tracking", type: VALUE_TYPES.OBJECT, defaultValue: {} },
        formMessage: { key: (type) => `formMessage.${type}`, type: VALUE_TYPES.STRING },
    },
}
